<template>
  <div class="about">
    <CryptoPortfolio />
  </div>
</template>
<script>
// @ is an alias to /src
import CryptoPortfolio from '@/components/CryptoPortfolio.vue'

export default {
  name: 'CryptoPortfolioView',
  components: {
    CryptoPortfolio
  }
}
</script>
