<template>
  <div>
    <v-btn-toggle
        v-model="list"
        tile
        color="deep-purple accent-3"
        group
      >
        <v-btn value="all">
          Alle
        </v-btn>

        <v-btn value="dates">
          Nach Datum
        </v-btn>

        <v-btn value="assets">
          Nach Asset
        </v-btn>

      </v-btn-toggle>


            <v-simple-table v-if="list==='all'">
              <template v-slot:default>
                <thead>
                  <th class="text-left">
                    Prämie
                  </th>
                  <th class="text-left">
                     Symbol
                   </th>
                   <th class="text-left">
                      Spot
                    </th>
                   <th class="text-left">
                     APR
                   </th>
                   <th class="text-left">
                     Delta
                   </th>

                </thead>
                <OptionListItem :items="options" >
                </OptionListItem>
              </template>
            </v-simple-table>

            <v-expansion-panels multiple inset v-if="list==='dates'">
              <v-expansion-panel v-for="(option,i) in dates"  :key="i">
                <v-expansion-panel-header>{{ option.symbol }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <th class="text-left">
                          Prämie
                        </th>
                        <th class="text-left">
                           Symbol
                         </th>
                         <th class="text-left">
                            Spot
                          </th>
                         <th class="text-left">
                           APR
                         </th>
                         <th class="text-left">
                           Delta
                         </th>

                      </thead>
                      <OptionListItem :items="option.items" >
                      </OptionListItem>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels multiple inset v-if="list==='assets'">
              <v-expansion-panel v-for="(option,i) in assets"  :key="i">
                <v-expansion-panel-header  v-if="option.items.length > 0">{{ option.symbol }}</v-expansion-panel-header>
                <v-expansion-panel-content v-if="option.items.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <th class="text-left">
                          Prämie
                        </th>
                        <th class="text-left">
                           Symbol
                         </th>
                         <th class="text-left">
                            Spot
                          </th>
                         <th class="text-left">
                           APR
                         </th>
                         <th class="text-left">
                           Delta
                         </th>

                      </thead>
                      <OptionListItem :items="option.items" >
                      </OptionListItem>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

  </div>
</template>
<script>
import OptionListItem from './OptionListItem';

const loadOptions = function() {
    var opts = {
  headers: {
    'mode':'cors'
  }
}
    let deltaUrl = 'https://api.delta.exchange/v2/tickers?contract_types=call_options,put_options&states=live';

    if (location.hostname !== 'localhost') {
      deltaUrl = 'proxy.php?url_to_fetch=https%3A%2F%2Fapi.delta.exchange%2Fv2%2Ftickers?contract_types=call_options,put_options&states=live';
    }
   fetch(deltaUrl, opts)
    .then(response => response.json())
    .then(data => {
      console.log(data);

      let assetsObj = {};
      let datesObj = {};
      data.result.forEach(item => {
        // {{ option.symbol }} - {{ option.expiration.apr }} - {{ option.expiration.days }} - {{ option.mark_price }} -  {{ option.oi_value_symbol }} - {{ option.strike_price }} - {{ option.contract_type }} - {{ option.turnover_usd }}
        if (item.contract_type !== 'call_options' && item.contract_type !== 'put_options') return;

        let exp = item.symbol.split("-")[3];
        let underlyingSymbol = item.symbol.split("-")[1];

        let expDay = exp.substring(0,2);
        let expMonth = parseInt(exp.substring(2,4)) - 1;
        let expYear = exp.substring(4,6);
        let expDate = new Date(20+expYear, expMonth, expDay);
        let days = Math.ceil((expDate - new Date())/1000/60/60/24);

        let praemie = parseFloat(item.mark_price);
        let strike = parseFloat(item.strike_price);
        const spot = parseFloat(item.spot_price);
        const gap = Math.round((strike / spot * 100) - 100, 2);
        let apr = (360*praemie)/(strike*days)*100;
        let deltaUrl = `https://www.delta.exchange/app/options_chain/trade/${underlyingSymbol}/${item.symbol}`;

        let itm = false;
        if
         (
           (
             (item.contract_type === "put_options") && (spot <= strike)
           ) ||
           (
             (item.contract_type === "call_options") && (spot >= strike)
           )
         ) {
          itm = true
        }

        let expiration = {
          foo: exp,
          itm,
          gap,
          deltaUrl,
          underlyingSymbol,
          expiration: {
            expDay,
            expMonth,
            expYear,
            expDate,
            days,
            diff: expDate - new Date(),
            apr
          }
        };
        let obj = {
          ...item,
          ...expiration
        }
        this.options.push(obj);

        if (assetsObj[underlyingSymbol] === undefined || assetsObj[underlyingSymbol] === null) {
          assetsObj[underlyingSymbol] = {
            symbol: underlyingSymbol,
            sort: underlyingSymbol,
            id: assetsObj.length,
            items: []
          };
        }

        if (datesObj[days] === undefined || datesObj[days] === null) {
          datesObj[days] = {
            symbol: `${exp} - ${days} days`,
            sort: days,
            id: datesObj.length,
            items: []
          };
        }


        assetsObj[underlyingSymbol].items.push(obj);
        datesObj[days].items.push(obj);
        //console.log(obj)
      });

      this.assets = assetsObj;

      this.dates = datesObj;


    });
 };

setInterval(loadOptions, 60000);
export default {
  name: 'DeltaExchange',

  data: () => {
    return {
      tab: null,
      list: 'all',
      options: [],
      dates: [],
      assets: {}
    }
  },
  components: {
    OptionListItem,
  },
  created: loadOptions
}
</script>
<style>
</style>
