<template>
  <div>
    Portfolio
  </div>
</template>
<script>


export default {

  name: 'CryptoPortfolio',
  created: () => {

    let req = {
     method: 'GET',
     mode: 'no-cors',
     accept: 'application/json'
    };

    fetch('https://www.optionsprofitcalculator.com/ajax/getOptions?stock=iwm&reqId=1', req)
     .then(response => response.json())
     .then(data => {
        console.log(data);

     });
  }
}
</script>
