<template>
  <div class="home">
    <DeltaExchange />
  </div>
</template>

<script>
// @ is an alias to /src
import DeltaExchange from '@/components/DeltaExchange.vue'

export default {
  name: 'HomeView',
  components: {
    DeltaExchange
  }
}
</script>
