<template>
  <tbody>
    <tr v-for="item in potentials" :key="item.product_id"  class="text-left">
      <td>{{ item.mark_price}} </td>
      <td><a :href="item.deltaUrl" target="delta-options-view">{{ item.symbol }}</a></td>

      <td class="text-left">({{item.gap}}%, {{item.expiration.days}} DTE) {{ item.spot_price }}</td>
      <td>{{ item.expiration.apr }}</td>
      <td>{{ item.greeks.delta}} </td>

    </tr>
  </tbody>
</template>
<script>
export default {
  name: 'OptionListItem',
  props: ["items"],
  computed: {
    potentials: function() {
      return this.items
      .filter(function(option){
        // console.log(Math.abs(option.gap) >= 5);
        return !option.itm  && option.expiration.apr >=30  && (Math.abs(option.gap) >= 5) && (option.greeks.delta >= -0.2 &&  option.greeks.delta <= 0.2);
      }).sort((a, b) => {
        // console.log(a.expiration.apr, b.expiration.apr);
        return b.expiration.apr - a.expiration.apr;
      });

    }
  }
}
</script>
