import HomeView from '../views/HomeView.vue'
import CryptoPortfolioView from '../views/CryptoPortfolioView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/crypto-portfolio',
    name: 'crypto-portfolio',
    component: CryptoPortfolioView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

export default routes;
